import React, { ReactNode, useEffect, useState } from "react";
import { Link } from "gatsby";
import { Layout } from "StaticPages/Layouts";
import Helmet from "react-helmet";
import { ScrollTrigger } from "mui-extensions/src/ScrollTrigger";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { FadeAndSlideAnimation } from "StaticPages/Components/Animations/FadeAndSlideAnimation";
import { TransitionImage } from "StaticPages/Components/TransitionImage";
import { css } from "@emotion/react";
import { SkewAnimation } from "StaticPages/Components/Animations/SkewAnimation";
import { PageSummary } from "StaticPages/Components/Commons/PageSummary";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import { DateTime } from "luxon";

export default () => {
    return <>
        <Helmet title="News | VISUALIZ INC" />
        <Layout>
            <Main />
        </Layout>
    </>;
};


const Main = () => {
    const { t } = useTranslation();
    const [newsItems, setNewsItems] = useState<any[]>([]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const { data } = await Axios.get<any>("https://visualiz-cms1.azurewebsites.net/public/sample_site/contents/news?fields=title,image,content");
        setNewsItems(data.collection);
    };

    return <>
        <main>
            <PageSummary
                title={t("お知らせ")}
                subTitle="News"
                description={<>
                    {t("株式会社VISUALIZのニュースや")}<br />
                    {t("最新情報をご覧いただけます。")}
                </>}
            />

            <Box>
                {
                    newsItems.sort((a, b) => DateTime.fromISO(b.publishIn) - DateTime.fromISO(a.publishIn)).map(news => (
                        <ServiceCollectionItem
                            key={news.contentId}
                            cid={news.contentId}
                            date={DateTime.fromISO(news.publishIn).toFormat("yyyy.MM.dd")}
                            imageEnabled={!!news.fields.image}
                            src={"https://visualiz-cms1.azurewebsites.net/" + news.fields.image}
                            title={news.fields.title}
                            detail={<>
                                <div dangerouslySetInnerHTML={{
                                    __html: news.fields.content
                                }}>
                                </div>
                            </>}
                        />
                    ))
                }

            </Box>

            <Box py={12} />
        </main>
    </>;
};

const ServiceCollectionItem = (
    {
        date,
        title,
        src,
        detail,
        imageEnabled,
        cid
    }: {
        date: string,
        src: string,
        imageEnabled?: boolean,
        title: string,
        detail: ReactNode,
        cid: string
    }
) => {
    const theme = useTheme();
    return (
        <ScrollTrigger once scrollStartOffset={"10%"}>
            {state =>
                <Box
                    mt={{ xs: 4, sm: 6, md: 10 }}
                    sx={{
                        width: "100%",
                        position: "relative"
                    }}
                    px={{
                        xs: 1,
                        sm: 2,
                        md: 3
                    }}
                    py={{
                        xs: 2,
                        sm: 0
                    }}
                    bgcolor={theme.palette.background.default}
                    textAlign="center"
                >
                    <Box
                        id={cid}
                        position="absolute"
                        top="-150px"
                    ></Box>

                    <Grid container
                        sx={{
                            width: "100%",
                            maxWidth: "1080px",
                            margin: "0 auto"
                        }}>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                        >
                            <Box
                                p={{
                                    xs: 1,
                                    sm: 2,
                                    md: 3
                                }}
                                sx={{
                                    height: "100%"
                                }}
                                textAlign="left"
                                display="flex"
                                flexDirection="column"
                                width={"100%"}
                                maxWidth={"480px"}
                            >
                                <Box mt="auto">
                                    <SkewAnimation
                                        in={state === "entered"}
                                        bgcolor={theme.palette.background.default}
                                        css={css`
                                            margin:0;
                                        `}
                                    >
                                        <Typography
                                            component="div"
                                        >{date}</Typography>
                                        <Typography
                                            variant="h5"
                                            component="h2"
                                            sx={{
                                            }}
                                        >{title}</Typography>
                                    </SkewAnimation>
                                </Box>
                                <Box
                                    mt={{
                                        xs: 0.5,
                                        sm: 1,
                                    }}
                                    my="auto"
                                    width={"100%"}
                                >
                                    <SkewAnimation
                                        in={state === "entered"}
                                        bgcolor={theme.palette.background.default}
                                        css={css({ width: "100%" })}
                                    >
                                        <Typography
                                            variant="body2"
                                            component="div"
                                        >{detail}</Typography>
                                    </SkewAnimation>
                                </Box>
                            </Box>
                        </Grid>
                        {imageEnabled &&
                            <Grid
                                item
                                xs={12}
                                sm={6}
                            >
                                <Box
                                    px={{
                                        xs: 1,
                                        sm: 2,
                                        md: 3
                                    }}
                                    sx={{
                                        width: "100%",
                                        height: "100%"
                                    }}
                                >
                                    <FadeAndSlideAnimation
                                        in={state === "entered"}
                                        transform={{
                                            rotate: "0deg",
                                            translate: {
                                                x: "0px",
                                                y: "0px",
                                            },
                                            scale: 0.92,
                                        }}
                                        css={css`
                                        height: 100%;
                                    `}
                                    >
                                        <img
                                            src={src}
                                            alt={title}
                                            css={css`
                                            height: 100%;
                                            width:100%;
                                            object-fit:cover;
                                        `}
                                        />
                                    </FadeAndSlideAnimation>
                                </Box>
                            </Grid>
                        }
                    </Grid>

                </Box>
            }
        </ScrollTrigger>
    );
};
